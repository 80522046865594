<template>
  <page-container>
    <div v-if="pdfEntry">
      <v-card class="text-center">
        <v-toolbar dark>
            <v-list-item-title>{{ pdfEntry.title }}</v-list-item-title>
            <!-- <v-list-item-subtitle style="white-space: normal;">{{ pdfEntry.publisher }}</v-list-item-subtitle> -->
        </v-toolbar>
        <iframe ref="embedPDF" class="mx-auto" style="border: none; height: 100vh; max-width: 800px;" width="100%"
          :src="`//drive.google.com/file/d/${this.pdfEntry.pdf}/preview`"></iframe>
      </v-card>
    </div>
    <div v-else class="text-center mt-10">
      <div>Sorry, the content is not found.</div>
      <v-btn to="/literature" dark large rounded style="text-transform: none;" color="secondary darken-1" class="mt-10">
        Explore Other Literature
      </v-btn>
    </div>
  </page-container>
</template>

<script>
import PDF_LIST from '@/data/literature_details'

export default {
  props: ['write_id'],
  pdfEntry: null,
  data: () => ({
    currentSong: {},
    state: 'loading'
  }),
  created () {
    this.pdfEntry = PDF_LIST.find(entry => entry.pdf === this.write_id)
    this.showSpinner()
  },
  components: {
    PageContainer: () => import('@/components/reusable/PageContainer')
  }
}
</script>
